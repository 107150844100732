import React, { useContext, useEffect, useState } from 'react';
import Sidebar from "../Common/Sidebar.js";
import Header from "../Common/Header.js";
import './style.css'; 
import { Link, useParams } from 'react-router-dom';
import { ManageContext } from '../../App.js';
import { channelLIst, getRndInteger, key, limitString, URL_SEARCH, VidTime } from '../Common/SimpleFunc.js';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import GridVid from './GridVid.js';
import { fireDatabase } from '../../Fire.js';
import { chennelWithStatus } from '../Database/Database.js';

const Search = (props) => {
 
    let { keyword } = useParams();
    const [vid, setVid] = useState([]);
    const [checkEmpty, setCheckEmpty] = useState(false); 
    const [videDetail, setVideoDetail] = useContext(ManageContext); 

    const getVedioList =(chennel)=>{
      let getVideos = '';
      setVid([]); // the setVid empty when load new video
      
      chennelWithStatus('approve').on('child_added', (snapshot) => {

        var options_search = {
          part: 'snippet',
          channelId: snapshot.key,
          key: key,
          q: keyword, 
          order: "date",
          maxResults: 10
      }
  
      URL_SEARCH.search = new URLSearchParams(options_search).toString();

      fetch(URL_SEARCH)
      .then(data => data.json())
      .then(result => {
      if (typeof result.etag !== 'undefined'){
        console.log("my testing lenght : " + result.items.length)
        result.items.map(data =>setVid(oldData=> [...oldData, data]) )
      }else {
        getVedioFire(keyword)
      }
      })
      .catch((error) => {
        console.log("I'm error" + error)
      });

      }
    )
      /*
        channelLIst.map((c, index) => {

          var options_search = {
            part: 'snippet',
            channelId: c,
            key: key,
            q: keyword, 
            order: "date",
            maxResults: 2
        }
    
        URL_SEARCH.search = new URLSearchParams(options_search).toString();
  
        fetch(URL_SEARCH)
        .then(data => data.json())
        .then(result => {
        if (typeof result.etag !== 'undefined'){
          result.items.map(data =>setVid(oldData=> [...oldData, data]) )
        }else {
          getVedioFire(keyword)
        }
        })
        .catch((error) => {
          console.log("I'm error" + error)
        });

        }
      )
        */
    }

    const getVedioFire=(video_title)=> {

      setVid([]); // the setVid empty when load new video
      let storeId = []; // store id then avoid duplicate id and also use for limit the video showing

      fireDatabase.ref('selectedChennel')
      .on('child_added', (snapshot) => {

          const data = snapshot.val().result.items;
           typeof data !== 'undefined'  &&
             data.map((res, index) => {
                  //replace regular expresson and limit character then string to array word by split 
                  video_title.replace(/regExp/g, '').slice(0, 15).split(' ').map(titleSlice => {

                      if(res.snippet.title.search(titleSlice) > - 1 && index < 2 && storeId.includes(res.id.videoId) != true && storeId.length < 5){
                          storeId.push(res.id.videoId);
                          setVid(oldData=> [...oldData, res]); 
                       }
                  })
              })
      })
  }

  const checkSrcResult =()=> {
      if(vid.length === 0) {
        setCheckEmpty(true);
      }
  }

    useEffect(()=>{

        getVedioList();

        setTimeout(() => {
          checkSrcResult();
        }, 5000);
    
    },[keyword])
 

    return (
        <>

{/* header */}
<Header/>

{/*   content area  */}
<div id="content_wrap">
  {/*  sidebar */}
  <Sidebar/>
{        console.log(vid.length)
}
  {/* - video content area */}
  <div class="vid_content_wrap">
    <div class="topBar"></div>
    <div id="vid_result" class={isMobile ? 'vid_content': 'vid_content_search'}>
     {
       checkEmpty != true && vid.length === 0 && <div class="loading_2"><img src={require('./img/loading.gif').default}/></div>
     } 
     {
       checkEmpty && vid.length === 0 && 
        <h3 className="nodataFound">No data found, search again</h3> 
     }
      {
      isMobile != true &&
       vid.map(data=> 
       <GridVid title={data.snippet.title} 
       description={data.snippet.description} 
       chennel={data.snippet.channelTitle}
       time={data.snippet.publishTime}
       videoId={data.id.videoId}
       image_url={data.snippet.thumbnails.medium.url}
       chennel_id={data.snippet.channelId}
       viewIn="search">
       </GridVid> 
       )
      }
        {
          isMobile &&
          vid.map(data=> 
          <GridVid title={data.snippet.title} 
          description={data.snippet.description} 
          chennel={data.snippet.channelTitle}
          time={data.snippet.publishTime}
          videoId={data.id.videoId}
          image_url={data.snippet.thumbnails.medium.url}
          chennel_id={data.snippet.channelId}
          viewIn="searchMobe">
          </GridVid> 
        )
        }
     {/*
       vid.map(data=>
        <Link onClick={()=> setVideoDetail({title: data.snippet.title, description: data.snippet.description, time: data.snippet.publishTime})}
           to={`/watch/${data.id.videoId}`} class="vid_Info_src" 
           style={{order: getRndInteger(0,100)}}>
          <img src={data.snippet.thumbnails.medium.url}/>
          <div class="info_wrap_src">
            <h3 class="title_2_src">
              <BrowserView>{limitString(data.snippet.title, 30)}</BrowserView>
              <MobileView>{data.snippet.title}</MobileView>
            </h3>
            <ul class="viewTime_2">
                <li class="view_1">16,914 views</li>
                <li class="time_1">{VidTime(data.snippet.publishTime)} ago</li>
              </ul>
              <div class="channelBrand_2">
                <p class="channelInfo_2">{data.snippet.channelTitle}</p>
              </div>
              <p class="vid_des">{data.snippet.description}</p>
          </div>
        </Link>
        )
       */ }

      </div>
    </div>
  </div>

        </>
    );
};

export default Search;