import React, { useContext, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Search from '../Home/Search';
import './style.css'; 
import Login from '../Dashboard/login/Login';
import { UserContext } from '../../App';
import { searchKeywordSave } from '../Database/Database';

const Header = () => {

    const [globalState, dispatch] = useContext(UserContext)
    const [searchData, setSearchData] =  useState(); 
    const [mobSrcBtn, setMobSrcBtn] =  useState(false); 
    
    let history = useHistory();

    const onchangeSearch =(event)=>{
        const inputValue = event.target.value;
        const inputLowerCast = inputValue.toLowerCase();
        const inputRemoveWhiteSpace = inputLowerCast.trim(); 
        
        setSearchData(inputRemoveWhiteSpace)
    }

    const SearchBtn=(event)=>{
        event.preventDefault();
        history.push("/search/"+searchData);

        const emailVarify = globalState.userRed.emailVerified; 
        const userinFire = globalState.userRed.checkUserInFire
        const uid = globalState.userRed.id; 
        const gender = globalState.userRed.gender; 
        const age = globalState.userRed.age; 
        const location = globalState.userRed.location; 


        if(emailVarify && userinFire){
            searchKeywordSave(searchData, uid, gender, age, location)
        }

    }
    const searchSwitch =() =>{
        setMobSrcBtn(!mobSrcBtn)
    }
    return (
        <>
        <BrowserView>
            <div id="header" class="fwidth">
                <div class="left_hdr  left">
                {/**  <div class="menuCall"><img src={require("./img/menu.png").default}/></div>  */}
                 <div class="icon"><Link to="/"><img src={require("./img/logo6.png").default}/></Link></div>
                </div>
                <div class="middle_hdr left">
                    <form onSubmit={SearchBtn} class="searchBox">
                    <input onChange={onchangeSearch} type="text" placeholder="search"/>
                    <button><img src={require("./img/zoom-icon.png").default}/></button>
                    </form>
                </div>
                <div class="right_hdr right">
                    <Login></Login>
                </div>
            </div>
        </BrowserView>
        <MobileView>
            <div id="header" class="fwidth mobHeaderWrap">
                {mobSrcBtn === false &&
                <div class="hdr_1">
                    <div class="left_hdr logrRp left">
                    <div class="icon mobIcon"><Link to="/"><img src={require("./img/logo6.png").default}/></Link></div>
                    </div>
                    <div class="middle_hdr srcBtn left">
                        <button onClick={searchSwitch}><img src={require("./img/zoom-icon.png").default}/></button>
                    </div>
                    <div class="right_hdr avtr right">
                    <Login></Login>
                    </div>
                </div>
                }
                { mobSrcBtn === true &&
                <div class="middle_hdr left mobSearchBar">
                    <button className="backBtn" onClick={searchSwitch}><ArrowBackIcon></ArrowBackIcon></button>
                    <form onSubmit={SearchBtn} class="searchBox">
                        <input onChange={onchangeSearch} type="text" placeholder="search"/>
                        <button><img src={require("./img/zoom-icon.png").default}/></button>
                    </form>
                </div>
                }
            </div>
        </MobileView>
        </>
    );
};

export default Header;