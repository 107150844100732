import './App.css';
import './responsive.css'; 
import Header from './component/Common/Header';
import Home from './component/Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Test from './component/Test/Test';
import Search from './component/Home/Search';
import InnertHtmlTest from './component/Test/InnertHtmlTest';
import Single from './component/Single/Single';
import Tests from './component/Home/Tests';
import { createContext, useReducer, useState } from 'react';
import Dashboard from './component/Dashboard/Dashboard';
import AddChennel from './component/Dashboard/AddChennel';
import AllChenel from './component/Dashboard/AllChenel';
import AdminList from './component/Dashboard/AdminList';
import AddAdmin from './component/Dashboard/AddAdmin';
import HomeFinal from './component/Home/HomeFinal';
import SingleVid from './component/Single/SingleVid';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileSidebar from './component/Common/MobileSidebar';
import Trending from './component/Home/Trending';
import { TheUserReducer, UserState } from './Reducer/UserReducer';
import History from './component/Home/History';
import Profile from './component/Home/Profile';
import PrivateRoute from './component/PrivateRout/PrivateRoute';
import Loginpage from './component/Home/Loginpage';
import AdminLogin from './component/Dashboard/login/AdminLogin';
import PrivateAdminRout from './component/PrivateRout/PrivateAdminRout';


export const  ManageContext = createContext(); 
export const  UserContext = createContext(); 

function App() {
  const [videDetail, setVideoDetail] = useState([]);
  const [globalState, dispatch] = useReducer(TheUserReducer, UserState); 

  return (
    <ManageContext.Provider value={[videDetail, setVideoDetail]}>
    <UserContext.Provider value={[globalState, dispatch]}>
    <div className="App">
     <Router>
        <Switch>    
        <Route exact path="/">
          <HomeFinal/>
          <MobileView><MobileSidebar menuSwitch='home'></MobileSidebar></MobileView>
        </Route>
        <Route path="/trend">
          <Trending/>
          <MobileView><MobileSidebar menuSwitch='trending'></MobileSidebar></MobileView>
        </Route>
        <Route path="/loginpage">
          <Loginpage/>
        </Route>
        <Route path="/search/:keyword">
           <Search />
            <MobileView><MobileSidebar></MobileSidebar></MobileView>
           </Route>
        <Route path="/watch/:idChennel/:id">
           <SingleVid />
           <MobileView><MobileSidebar></MobileSidebar></MobileView>
        </Route>
        <PrivateRoute path="/history">
          <History></History>
          <MobileView><MobileSidebar menuSwitch='history'></MobileSidebar></MobileView>
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <Profile></Profile>
        </PrivateRoute>
        <PrivateAdminRout path="/dashboard/:DashComponent">
             <Dashboard/>
        </PrivateAdminRout>
        <Route path="/shsAdmin">
             <AdminLogin/>
        </Route>
           {/**
           <Route path="/allChennel">
             <Dashboard titile="All Chennel List" component={<AllChenel/>}/>
           </Route>
           <Route path="/createAdmin">
             <Dashboard titile="Create New Admin" component={<AddAdmin/>}/>
           </Route>
           <Route path="/adminList">
             <Dashboard titile="Admin List" component={<AdminList/>}/>
           </Route>
            */}
        </Switch>
    </Router> 
    </div>
    </UserContext.Provider>
    </ManageContext.Provider>

  );
}

export default App;
