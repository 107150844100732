import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { fireDatabase } from '../../Fire';
import GridVid from '../Home/GridVid';

const SugestFireVid = ({video_title}) => {
    const [vid, setVid] = useState([]); 

    const getVedioList=()=> {

        setVid([]); // the setVid empty when load new video
        let storeId = []; // store id then avoid duplicate id and also use for limit the video showing
        console.log("sugest video : " + video_title);
        fireDatabase.ref('selectedChennel')
        .on('child_added', (snapshot) => {

                const data = snapshot.val().result.items;

             typeof data !== 'undefined'  &&
               data.map((res, index) => {
                    //replace regular expresson and limit character then string to array word by split 
                    video_title.replace(/regExp/g, '').slice(0, 15).split(' ').map(titleSlice => {

                        if(res.snippet.title.search(titleSlice) > - 1 && index < 2 && storeId.includes(res.id.videoId) != true && storeId.length < 5){
                            storeId.push(res.id.videoId);
                            console.log(storeId)
                            setVid(oldData=> [...oldData, res]); 
                         }

                    })
                })
        })
    }

    useEffect(()=>{
        getVedioList()
    },[video_title])
    
    return (
        <>
        
      <div id="releatedVid_result" class="releatedVid_wrap">
        <BrowserView>
    {
       vid.map(data=> 
            <GridVid 
            title={data.snippet.title} 
            description={data.snippet.description} 
            chennel={data.snippet.channelTitle}
            time={data.snippet.publishTime}
            videoId={data.id.videoId}
            image_url={data.snippet.thumbnails.medium.url}
            chennel_id={data.snippet.channelId}
            viewIn="sugestVidFire">
            </GridVid> 
            )
      }
      </BrowserView>
      <MobileView>
      {
       vid.map(data=> 
            <GridVid 
            title={data.snippet.title} 
            description={data.snippet.description} 
            chennel={data.snippet.channelTitle}
            time={data.snippet.publishTime}
            videoId={data.id.videoId}
            image_url={data.snippet.thumbnails.medium.url}
            chennel_id={data.snippet.channelId}
            viewIn="sugestVidFire">
            </GridVid> 
            )
      }
      </MobileView>
      </div>
        </>
    );
};

export default SugestFireVid;