import React, { useContext, useEffect, useState } from 'react';
import Header from "../Common/Header.js";
import Sidebar from "../Common/Sidebar.js";
import './style.css'; 
import GridVid from './GridVid.js';
import { fireDatabase } from '../../Fire.js';
import { UserContext } from '../../App.js';

const History = () => {

  const [globalState, dispatch] = useContext(UserContext);
  const [videoDetail, setVideoDetail] = useState([]);

  const getDataFromFire=()=> {

    const userUId = globalState.userRed.id; 
    const emailVarify =  globalState.userRed.emailVerified; 
    const userInFire = globalState.userRed.checkUserInFire;

    console.log("user id : " +  userUId + " | email : " + emailVarify + " | in fire : " + userInFire)

    if(emailVarify && userInFire){
      fireDatabase.ref('history/'+userUId).once('value',(snapshot)=> {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          const d =new Date(childData.date); 
          const vidDate = d.getDate();
          const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          const vidMonth=  months[d.getMonth()];
          const vidSetFullDate = vidMonth + " " + vidDate; 
  
          const detailsVid = {
            videoId : childKey,
            chennel: childData.chennelId, 
            title: childData.title,
            date: vidSetFullDate
          }

          setVideoDetail(oldData=> [...oldData,detailsVid]); 
         // setVid(oldData=> [...oldData, data])

        });
      })
    }

  }

  useEffect(()=>{
    getDataFromFire();
    console.log("Dahka Bangladesh")
  },[globalState.userRed.checkUserInFire])
    return (
        <>
{/* header */}
<Header/>
{/*   content area */}

<div id="content_wrap">
  {/*  sidebar*/}
  <Sidebar menuSwitch='history'/>

  {/* - video content area*/}
  <div class="vid_content_wrap">
    <div class="topBar"></div>
    <div id="vid_result" class="vid_content">

  { 
 // globalState.userRed.emailVerified && globalState.userRed.checkUserInFire &&
    videoDetail.map(data =>
      <GridVid 
      title={data.title} 
      time={data.date}
      videoId={data.videoId}
      image_url={`https://img.youtube.com/vi/${data.videoId}/mqdefault.jpg`}
      chennel_id={data.chennel}
      viewIn="history">
      </GridVid>
    )
    
  }
  {/*
      !globalState.userRed.emailVerified && 
      <div className="historyLogin">
        <p className="histInfo">You need to login to see the history</p>
        <Login></Login>
      </div>
*/
  }
      </div>
    </div>

  </div>
        </>
    );
};

export default History;