import React, { useContext, useEffect, useReducer, useState } from 'react';
import Header from "../Common/Header.js";
import './style.css'; 
import { useParams } from 'react-router-dom';
import SugestVid from './SugestVid.js';
import { ManageContext } from '../../App.js';
import { FakeInfo } from '../FakeInfo/FakeInfo.js';
import { key, URL_VIDEOS, VidTime } from '../Common/SimpleFunc.js';
import { TheVideoReducer, videoState } from '../../Reducer/VideoReducer.js';
import { videInfoFromFire } from '../Database/Database.js';
import { analiticsFire, fireDatabase } from '../../Fire.js';
import SugestFireVid from './SugestFireVid.js';
import SugestTubeVid from './SugestTubeVid.js';
import ViewHistory from './ViewHistory.js';

const SingleVid = () => {
  
    let { idChennel, id } = useParams();
    const [singleVidInfo, setSingleVidInfo] =  useState([{
      "etag": "Cap24oK0qanjLAtOnxFM5VggIU4",
      "id": {
        "kind": "youtube#video",
        "videoId": "-IkVeqnp_9Q"
      },
      "kind": "youtube#searchResult",
      "snippet": {
        "channelId": "UC6z-LtmrNxcFYb3sohNgNfQ",
        "channelTitle": "Voice of True tv",
        "description": "none",
        "liveBroadcastContent": "none",
        "publishTime": "2021-03-17T08:00:00Z",
        "publishedAt": "2021-03-17T08:00:00Z",
        "thumbnails": {
          "default": {
            "height": 90,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/default.jpg",
            "width": 120
          },
          "high": {
            "height": 360,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/hqdefault.jpg",
            "width": 480
          },
          "medium": {
            "height": 180,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/mqdefault.jpg",
            "width": 320
          }
        },
        "title":  "none"
      }, 
      "statistics": {
        "viewCount": "none",
        "likeCount": "none",
        "dislikeCount": "none",
        "favoriteCount": "none",
        "commentCount": "none"
      }
    }]); 
    const [vidInfoFire, setVidInfoFire] = useState({
      "etag": "Cap24oK0qanjLAtOnxFM5VggIU4",
      "id": {
        "kind": "youtube#video",
        "videoId": "-IkVeqnp_9Q"
      },
      "kind": "youtube#searchResult",
      "snippet": {
        "channelId": "UC6z-LtmrNxcFYb3sohNgNfQ",
        "channelTitle": "Voice of True tv",
        "description": "none",
        "liveBroadcastContent": "none",
        "publishTime": "none",
        "publishedAt": "none",
        "thumbnails": {
          "default": {
            "height": 90,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/default.jpg",
            "width": 120
          },
          "high": {
            "height": 360,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/hqdefault.jpg",
            "width": 480
          },
          "medium": {
            "height": 180,
            "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/mqdefault.jpg",
            "width": 320
          }
        },
        "title":  "none"
      }, 
      "statistics": {
        "viewCount": "none",
        "likeCount": "none",
        "dislikeCount": "none",
        "favoriteCount": "none",
        "commentCount": "none"
      }
    });
    const [state, dispatch] = useReducer(TheVideoReducer, videoState);
    const [videDetail] = useContext(ManageContext);

    const getVidInfo =()=>{

      //SAMPLE LINK
      //https://www.googleapis.com/youtube/v3/videos?part=snippet&part=statistics&id=9JRBFNQFzzA&key=AIzaSyAwHonbVFKdfFLoByCAH58OAeRhfVK2Jl4

      var options_search = {
        part: 'snippet&part=statistics', // in the pairs use another pairs and then decode when use the url
        id: id, // id is paramas pass id
        key: key
    }

    URL_VIDEOS.search = new URLSearchParams(options_search).toString();

    // URL_VIDEOS decoded by decodeURIComponent()  
    fetch(decodeURIComponent(URL_VIDEOS))
    .then(data => data.json())
    .then(result =>
        typeof result.items !== 'undefined' && setSingleVidInfo(result.items)
        )
    .catch((error) => {
      console.error('Error:', error);
    });
}
const VideoInfoFromFire=()=> {
          
    fireDatabase.ref('selectedChennel/'+ idChennel+'/result/items').once('child_added', (snapshot) => {
  
      if(snapshot.val().id.videoId == id){
 
         let data = snapshot.val();
               setVidInfoFire(data)
           }
        }
    )
    }
    const vidTitle = ()=> {
        try{ 
            
        if(typeof videDetail.title !== 'undefined' && videDetail.title.length > 0){
            return videDetail.title;
         } else if (singleVidInfo[0].snippet.title !== 'none'){
            return singleVidInfo[0].snippet.title; 
         } else if (typeof videDetail.title === 'undefined' && vidInfoFire.snippet.title != 'none') {
           return  vidInfoFire.snippet.title;
         }else {
           return "loading ..."
         }
    }catch(err){
    
    //console.log ("shafayat : "+err)
  }

}
const vidDescription =()=> {


  try{
    if(typeof videDetail.description !== 'undefined' && videDetail.description.length > 0 ){
      return videDetail.description; 
    }else if (typeof videDetail.description === 'undefined' && vidInfoFire.snippet.description != 'none'){
     // console.log('undefined load from firebase')
      return vidInfoFire.snippet.description; 
    }else {
      return "loading ..."
    }
  }catch(err){
    console.log(err)
  }

}

const vidDateAgo =() => {

      try{
        if (videDetail.time > 0){
          return VidTime(videDetail.time)
        } else if (singleVidInfo[0].snippet.publishedAt !== 'none'){
            return VidTime(singleVidInfo[0].snippet.publishedAt);
        }
      }catch(err){}
    }

  const analiticsSubmit=(title, chennelId , videoId)=>{
    analiticsFire.logEvent('pageView', { title: title, chennelId : chennelId, videoId : videoId});
  }

useEffect(()=>{
  getVidInfo()

  // run firebase realtime database when videDetails details is undefined
  if(typeof videDetail.description === 'undefined'){
    console.log('i\'m loding becoze ' + typeof videDetail.description)
    VideoInfoFromFire()
  }
  // firebase analitics submit data
  analiticsSubmit(vidTitle(), idChennel, id)
},[videDetail])

    return (
        <>
<Header/>

{ console.log("video id : " + id)}

{/* --- video content area  */}
  <div class="view_page_wrap">
    <div class="sng_video_wrap">
      <div class="video_view">
        <iframe id="video" class="vidIframe"  src={'https://www.youtube.com/embed/'+id+'?rel=0'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
        <h1 class="title_2">{
          vidTitle()
               //vidInfoFire.snippet.title != 'none' && vidInfoFire.snippet.title
        }</h1>
          <ul class="viewTime">
          { singleVidInfo[0].statistics.viewCount !== 'none' && 
            <li class="view_1">{singleVidInfo[0].statistics.viewCount} views</li> 
          }
          {
            <li class="time_1">{vidDateAgo()} ago</li>
          }
          </ul>
          <hr class="hr_1"></hr>

          <p>{singleVidInfo[0].snippet.description === 'none' && vidDescription()}</p>
         {
         singleVidInfo[0].snippet.description !== 'none'  && 
          //replace \n to <br /> . use \n/g for global check
           <p dangerouslySetInnerHTML={{__html: singleVidInfo[0].snippet.description.replace(/\n/g, "<br />")}} class="vidDec"></p> 
          }

      </div>
      {/**
       *  condition1 ?  ifTrue_vl1 : condition2 ? vl2 : vl3 
      */}
      {
       singleVidInfo[0].snippet.title === 'none' && <SugestFireVid video_title={vidInfoFire.snippet.title != 'none' ? vidInfoFire.snippet.title : typeof videDetail.title !== 'undefined' ? videDetail.title : 'waz'}/>
      }

      {
     singleVidInfo[0].snippet.title !== 'none' && <SugestTubeVid video_title={vidInfoFire.snippet.title != 'none' ? vidInfoFire.snippet.title : singleVidInfo[0].snippet.title !== 'none' ? singleVidInfo[0].snippet.title : typeof videDetail.title !== 'undefined' ? videDetail.title : 'waz'}/>
      }
    </div>
  </div>
  <ViewHistory idChennel={idChennel} videoId={id} title={vidTitle()}></ViewHistory>
        </>
    );
};

export default SingleVid;