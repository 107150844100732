import { fireDatabase } from "../../Fire";
import { key, URL_SEARCH } from "../Common/SimpleFunc";

const getAllData=()=>{
fireDatabase.ref()
}

export const addChenel=(chennelId, userName)=>{
    const d = new Date();
    const t = d.getTime();

  fireDatabase.ref('chennel/'+ chennelId).once('value', (snapshot)=>{

    if(snapshot.exists() != true){

      fireDatabase.ref('chennel/'+ chennelId).set({
        approve: 0, 
        decline: 0,
        timestamp: t,
        status: "pending",
        userName: userName
        }, (error) => {
            if (error) {
            console.log(error)
            } else {
                alert("save successfully")
                }
            }
        );

    }else {
      alert("This chennel is already exists")
    }

  })

}

export const chennelUpdate =(id, like, decline, timestamp, status) => {
    let updateStatus = status; 

    if(like > 2){
        updateStatus = "approve";
    }else if (decline > 2){
        updateStatus = "decline";
    }

    if(updateStatus == "approve"){
        selectChennel(id, like, decline, timestamp, updateStatus);
    }else {
      updateChennel=(id, like, decline, timestamp, updateStatus);
    }


}


export const selectChennel =(chennel, like, decline, timestamp, status)=>{

  let getVideos = '';

  const d = new Date();
  const t = d.getTime();

      var options_search = {
        part: 'snippet',
        channelId: chennel,
        key: key, 
       // q: "waz", 
        order: "date",
        maxResults: 50
    }

    URL_SEARCH.search = new URLSearchParams(options_search).toString();

    fetch(URL_SEARCH)
    .then(data => data.json())
    .then(result => 

      fireDatabase.ref('selectedChennel/'+ chennel).set({ 
        status : status, 
        timestamp: t,
        result},
              (error) => {
              if (error) {
              console.log(error);
              alert("Youtube api have some issue!!")
              } else {
                  updateChennel=(chennel, like, decline, timestamp, status);
                  alert("The chennel is now approve")
                  }
              }
          )
    )
    .catch((error) => {
      alert("Youtube api have some issue!!")
      console.error('Error:', error);
    });
    
}


const updateChennel=(id, like, decline, timestamp, status)=> {

  let postData = {
    approve: like,
    decline: decline,
    timestamp: timestamp,
    status: status
  };

  let updates = {};
  updates['/chennel/' + id] = postData;

  return fireDatabase.ref().update(updates);
}

export const adminUpdate =(id, name, email, timestamp, status) => {

  var postData = {
      name: name,
      email: email,
      timestamp: timestamp,
      status: status
    };
  
    var updates = {};
    updates['/admin/' + id] = postData;
  
    return fireDatabase.ref().update(updates);
}


export const removeChennel =(id)=>{
  return  fireDatabase.ref('chennel/'+ id).remove();
}

export const chennelWithStatus = (status)=>{
    // show data where status value equal to status value
   return fireDatabase.ref('chennel/').orderByChild('status').equalTo(status)
}

export const adminWithStatus = (status)=>{
  // show data where status value equal to status value
 return fireDatabase.ref('admin/').orderByChild('status').equalTo(status)
}
export const updateWithNewData =(chennelId)=> {

  let loadData = [];
  let youtubeData = []; 

  fireDatabase.ref('selectedChennel/'+ chennelId+'/result/items').once('value', (snapshot) => {
    loadData.push(snapshot.val());
    }
  )
  console.log(loadData);

  // for youtube search
   var options_search = {
          part: 'snippet',
          channelId: chennelId,
          key: key, 
         // q: "waz", 
          order: "date",
          maxResults: 50
      }
  
      URL_SEARCH.search = new URLSearchParams(options_search).toString();

      fetch(URL_SEARCH)
      .then(data => data.json())
      .then(result => 
        youtubeData.push(result.items)
      )
      console.log(youtubeData)

      setTimeout(() => {

       loadData[0].map(res =>{
         youtubeData[0].map(tube => {
           if(res.id.videoId.indexOf(tube.id.videoId) > -1){
            // console.log(tube)
            loadData[0].push(tube)
           }
         })

      } )
      
      var updates = {};
      updates['selectedChennel/'+ chennelId+'/result/items'] = loadData[0];
      try{
        fireDatabase.ref().update(updates);
        alert("Update success")
      }catch(err){

      } 
      }, 5000);
} 

  export const chennelDataGetFire=(dispatch)=> {

    let listData="";
    fireDatabase.ref('selectedChennel').on('child_added', (snapshot) => {
  
        const data = snapshot.val();
        listData = {id: snapshot.key, items: data.result.items}; // push data in array 
        
        dispatch({
          type: "ADD_FIREBASEDATA_CHENNEL",
          DataListFromFire: listData
        })

        }
    )
  }

  export const videInfoFromFire=(chennelId, videoId, dispatch)=> {
    
    fireDatabase.ref('selectedChennel/'+ chennelId+'/result/items').once('child_added', (snapshot) => {
  
     if(snapshot.val().id.videoId == videoId){

        let data = snapshot.val();
    //    console.log(data);
      //  listData = {id: snapshot.key, items: data.result.items}; // push data in array 
          dispatch({
            type: "ADD_SINGLE_VIDINFO",
            videoData: data
          })          
          }
       }
    )
    
  }

  export const userSet =(uid, email, name, location, gender, age) => {
    
    fireDatabase.ref('user/'+ uid).once('value', (snapshot) => {
        if(snapshot.exists() != true){
          userPushInFire();
        }
      }
    )

    const userPushInFire =()=>{

      fireDatabase.ref('user/'+ uid).set({ 
        name : name, 
        email: email,
        location: location,
        gender: gender, 
        age: age,  
      },
        (error) => {
          if (error) {
          console.log(error)
          } else {
              console.log("save successfully")
              }
          }
      )
    }


  }

  export const historySet =(uid, idChennel, videoId, title, date)=>{

    fireDatabase.ref('history/'+ uid +'/' + videoId).set({ 
      chennelId: idChennel,
      title: title,
      date: date
    },
      (error) => {
        if (error) {
        console.log(error)
        } else {
            console.log("save successfully")
            }
        }
    )
  }
  
  export const searchKeywordSave =(keyword, uid, gender, age, location)=>{
    const d = new Date();
    const year = d.getFullYear(); 
    const month = d.getMonth(); 
    const setDate = year + ', '+ month; 
    
    fireDatabase.ref('keywords/'+ setDate + '/' +  keyword + '/'+ uid).set({ 
      gender: gender,
      age: age, 
      location: location
    },
      (error) => {
        if (error) {
        console.log(error)
        } else {
            console.log("save successfully")
            }
        }
    )
  }
  export const test =()=> {return console.log("Thank you")}
//export default getAllData; 