export const videoState = {
    approveChennelRed: [],
    blockChennelRed: [],
    trashChennelRed: [], 
    pendingChennelRed:[],
    selectedChennelRed:[],
    allFireDataRed:[],
    singleVidInfoRed:[{
        "etag": "Cap24oK0qanjLAtOnxFM5VggIU4",
        "id": {
          "kind": "youtube#video",
          "videoId": "-IkVeqnp_9Q"
        },
        "kind": "youtube#searchResult",
        "snippet": {
          "channelId": "UC6z-LtmrNxcFYb3sohNgNfQ",
          "channelTitle": "Voice of True tv",
          "description": "testing",
          "liveBroadcastContent": "none",
          "publishTime": "2021-03-17T08:00:00Z",
          "publishedAt": "2021-03-17T08:00:00Z",
          "thumbnails": {
            "default": {
              "height": 90,
              "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/default.jpg",
              "width": 120
            },
            "high": {
              "height": 360,
              "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/hqdefault.jpg",
              "width": 480
            },
            "medium": {
              "height": 180,
              "url": "https://i.ytimg.com/vi/-IkVeqnp_9Q/mqdefault.jpg",
              "width": 320
            }
          },
          "title":  "testing"
        }
      }],
    dataSwitchRed:true
}

export const TheVideoReducer =(state, action) =>{

    console.log(state + " :" +  action.allChennelDataList)
    switch(action.type){
        case "ADD_APPROVE_CHENNEL": 
            const newApproveList = [...state.approveChennelRed, action.approveList]
            return {approveChennelRed: newApproveList}; 
        case "ADD_BLOCK_CHENNEL": 
            const newBlockList = [...state.blockChennelRed, action.blockList]
            return {blockChennelRed: newBlockList}; 
        case "ADD_TRASH_CHENNEL": 
            const newTrashList = [...state.trashChennelRed, action.trashList]
            return {trashChennelRed: newTrashList}; 
        case "ADD_PENDING_CHENNEL": 
            const newPendingList = [...state.pendingChennelRed, action.pendingList]
            return {pendingChennelRed: newPendingList};  
        case "ADD_FIREBASEDATA_CHENNEL": 
            const newFireDataList = [...state.allFireDataRed, action.DataListFromFire]
            return {allFireDataRed: newFireDataList}; 
        case "ADD_SELECTED_CHENNEL":
          const newSelectedList = [...state.selectedChennelRed, action.selectedList]
          return {selectedChennelRed: newSelectedList};  
        case "ADD_SINGLE_VIDINFO": 
            return {singleVidInfoRed: action.videoData}; 
      /*  case "REMOVE_APPROVE_CHENNEL": 
            const appRemainChennel = state.approveChennelRed.filter(data => data != action.remainApproveList)
            return {approveChennelRed: appRemainChennel}; */
        default:
            return state; 
    }
    
}