import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { fireDatabase } from '../../Fire';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const createData = (name, calories, fat, carbs, protein)=> {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const AllChenel = () => {
  const classes = useStyles();
  const [chennelList, setChennelList] = useState([]);

  const getAllChennel=()=>{
    let listData = [];

    fireDatabase.ref('chennel/').on('child_added', (snapshot) => {
      const data = snapshot.val();

      listData.push({id: snapshot.key, status: data.status}); // push data in array 
      setChennelList(listData); // save the data to state

    });

  }

  const removeChennel = (id) => {
    fireDatabase.ref('chennel/'+ id).remove();
    document.getElementById(id).style.display='none';

  }

  useEffect(()=>{
    getAllChennel(); 
  },[])

  return (
    <>

    <h3>Pending Chennel List</h3>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Chennel url</TableCell>
            <TableCell align="right">Chennel Id</TableCell>
            <TableCell align="right">Approve</TableCell>
            <TableCell align="right">Decline</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="pendingList">
        
          {chennelList.map((row) => (
            <TableRow id={row.id} key={row.id}>
              <TableCell component="th" scope="row">
               <a href={'https://www.youtube.com/channel/'+row.id}>{row.id}</a>
              </TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.approve}</TableCell>
              <TableCell align="right">{row.decline}</TableCell>
              <TableCell align="right">
                <button className="approve"><ThumbUpIcon></ThumbUpIcon></button>
                <button className="disline"><ThumbDownIcon></ThumbDownIcon></button>
                <button className="allow"><CheckIcon></CheckIcon></button>
                <button onClick={()=> removeChennel(row.id)} className="delete"><ClearIcon></ClearIcon></button>
              </TableCell>
            </TableRow>
          ))
          }

        </TableBody>
      </Table>
    </TableContainer>
    <br></br>
    <h2>All Chennel List</h2>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default AllChenel; 