import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import fire from '../../../Fire';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useHistory } from 'react-router';
import { UserContext } from '../../../App';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {/*
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },*/
  },
}))(MenuItem);

const  AfterLoginAdminMenu = () => {
    

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [globalState, dispatch] = useContext(UserContext)

  const history = useHistory();

  const goToTePage=(path)=>{
    history.push("/"+ path);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogOut =() =>{
    fire.auth().signOut().then(() => {

       dispatch({
          type: "ADD_USER_INFO",
          userInfo: ""
      })

    }).catch((error) => {
        // An error happened.
      });
  }

  return (
    <>
   
        <Button className="avatar_1 loginAvt" onClick={handleClick}>
          <img className="avatIcon" src={globalState.userRed.photo}/>
        </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/** 
        <StyledMenuItem onClick={()=>goToTePage('profile')}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </StyledMenuItem>
   
        <StyledMenuItem>
          <ListItemIcon>
            <SaveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Save video" />
        </StyledMenuItem>
    */}
        <StyledMenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}

export default AfterLoginAdminMenu;