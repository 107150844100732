import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { fireDatabase } from '../../../Fire';
import { dateTimeShow } from '../../Common/SimpleFunc';
import { chennelUpdate, chennelWithStatus, removeChennel } from '../../Database/Database';
import {TheVideoReducer, videoState} from '../../../Reducer/VideoReducer'; 

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const PendingChennel = () => {
  const classes = useStyles();
  const [chennelList, setChennelList] = useState([]);
  const [state, dispatch] = useReducer(TheVideoReducer, videoState) 
  const [test, setTest] = useState([]);

  const getAllChennel=()=>{
    let listData = [];

    chennelWithStatus('pending').on('child_added', (snapshot) => {
  
        const data = snapshot.val();
        //listData.push({id: snapshot.key, approve: data.approve, decline: data.decline, status: data.status, timestamp: data.timestamp}); // push data in array 
        listData = {id: snapshot.key, approve: data.approve, decline: data.decline, status: data.status, timestamp: data.timestamp}; // push data in array 

        dispatch({
          type: "ADD_PENDING_CHENNEL",
          pendingList: listData
        })

        }
    )
  }

  const voteChennel = (id, approve, decline, timestamp, status) => {

    chennelUpdate(id, approve, decline, timestamp, status)
    
  }
  const deleteChennel= (id) => {
    removeChennel(id);// database remove the chennel from list
  }
  const hideRow = (id) => {
    document.getElementById(id).style.display='none';
  }
  useEffect(()=>{
        getAllChennel(); 
  },[])

  return (
    <>
    
    
    <div className="dsTitle">
        <h2></h2>
    </div>
    <h3>Pending Chennel List</h3>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Chennel url</TableCell>
            <TableCell align="right">Chennel Id</TableCell>
            <TableCell align="right">Approve</TableCell>
            <TableCell align="right">Decline</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Add Time</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="pendingList">
        
          {state.pendingChennelRed.map((row) => (
            <TableRow id={row.id} key={row.id}>
              <TableCell component="th" scope="row">
               <a target="_blank" href={'https://www.youtube.com/channel/'+row.id}>{row.id}</a>
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.approve}</TableCell>
              <TableCell align="right">{row.decline}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{dateTimeShow(row.timestamp)} ago</TableCell>
              <TableCell align="right">
                <button onClick={()=> voteChennel(row.id, row.approve + 1,row.decline, row.timestamp, row.status)} className="approve"><ThumbUpIcon></ThumbUpIcon></button>
                <button onClick={()=> voteChennel(row.id, row.approve,row.decline + 1, row.timestamp, row.status)} className="disline"><ThumbDownIcon></ThumbDownIcon></button>
                <button onClick={()=> {voteChennel(row.id, row.approve,row.decline, row.timestamp, 'approve'); hideRow(row.id)}} className="allow"><CheckIcon></CheckIcon></button>
                <button onClick={()=> {deleteChennel(row.id) ; hideRow(row.id)}} className="delete"><ClearIcon></ClearIcon></button>
              </TableCell>
            </TableRow>
          ))
          }

        </TableBody>
      </Table>
    </TableContainer>
    
    </>
  );
}

export default PendingChennel; 