import React, { useContext, useEffect, useReducer, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { ManageContext } from '../../App.js';
import Header from "../Common/Header.js";
import Sidebar from "../Common/Sidebar.js";
import { channelLIst, getRandomInt, getRndInteger, key, limitString, URL_SEARCH, VidTime } from '../Common/SimpleFunc.js';
import EmptyLoading from './EmptyLoading.js';
import './style.css'; 
import { getChennelList, getVideoData } from '../Database/DataLoad.js';
import { chennelWithStatus } from '../Database/Database.js';
import AlternativeData from './AlternativeData.js';
import GridVid from './GridVid.js';
import { TheVideoDataReducer, videoDataState } from '../../Reducer/VideoDataReducer.js';

const HomeFinal = () => {

    const [vid, setVid] = useState([]); 
    const [state, dispatch] = useReducer(TheVideoDataReducer, videoDataState) 

    const [videDetail, setVideoDetail] = useContext(ManageContext); 


    const getVedioList =()=>{

     //   getVideoData(dispatch); 
     
    let randomInt = getRandomInt(0,10); 
    let orderbySet = randomInt > 5 ? 'date' : 'relevance'; 
    let isActiveTube  = true; 

chennelWithStatus('approve').on('child_added', (snapshot) => {

    var options_search = {
        part: 'snippet',
        channelId: snapshot.key,
        key: key, 
       // q: "waz", 
        order: orderbySet,
        maxResults: 10
    }

    URL_SEARCH.search = new URLSearchParams(options_search).toString();

    fetch(URL_SEARCH)
    .then(data => data.json())
    .then(result => 
      result.items.map(data =>{
         // console.log(data);
        dispatch({
            type: "ADD_VIDEO_DATA",
            allChennelDataList: data
          })

        /*  dispatch({
            type: "ADD_DATA_SWITCH", 
            dataSwitch:true
           })
           */
      })
    )
    .catch((error) => {
      isActiveTube = false; 
      dispatch({
        type: "ADD_DATA_SWITCH", 
        dataSwitch:isActiveTube
       })
    });

    }


  )

        
    }

    useEffect(()=>{
      //getChennelList(dispatch, 'approve'); // load from dataload 
      getVedioList();
    },[])

    return (
        <>
{/* header */}
<Header/>
{/*   content area */}

<div id="content_wrap">
  {/*  sidebar*/}
  <Sidebar menuSwitch='home'/>

  {/* - video content area*/}
  <div class="vid_content_wrap">
    <div class="topBar"></div>
    <div id="vid_result" class="vid_content">
      {/* <AlternativeData/> */} 
      {/*
      
         state.dataSwitchRed == true &&
      typeof state.chennelDataRed !== 'undefined' && state.chennelDataRed.length != 0 && state.chennelDataRed.map(data =>
         <li>{data.snippet.title}</li>
         )
      */ }
    {
      
      // check this point
      typeof state.videoDataRed !== 'undefined' && state.videoDataRed.length > 7 &&
      state.videoDataRed.map(data => 
      <GridVid 
        title={data.snippet.title} 
        description={data.snippet.description} 
        chennel={data.snippet.channelTitle}
        time={data.snippet.publishTime}
        videoId={data.id.videoId}
        image_url={data.snippet.thumbnails.medium.url}
        chennel_id={data.snippet.channelId}
        viewIn="home">
        </GridVid>
    )
   
    }
    {console.log('yotuube : ' + typeof  state.videoDataRed)}
    { /* state.dataSwitchRed == true &&  vid.length === 0 && <EmptyLoading/>  */}
    { typeof state.videoDataRed !== 'undefined' && state.videoDataRed.length === 0 && <EmptyLoading/> }
    {typeof state.videoDataRed === 'undefined' && <AlternativeData></AlternativeData> }
      { /*
        vid.map(data =>
            
        <Link onClick={()=> setVideoDetail({title: data.snippet.title, description: data.snippet.description, time: data.snippet.publishTime})}
           to={`/watch/${data.id.videoId}`} class="vid_Info" 
           style={{order: getRndInteger(0,100)}}>
          <img src={data.snippet.thumbnails.medium.url}/>
          <div class="info_wrap">
            <div class="info_1">
              <h3 class="title_1">
                <BrowserView>{limitString(data.snippet.title, 30)}</BrowserView>
                <MobileView>{data.snippet.title}</MobileView>
              </h3>
              <p class="channelInfo">{data.snippet.channelTitle}</p>
              <ul class="viewTime">
                <li class="time_1">{VidTime(data.snippet.publishTime)} ago</li>
              </ul>
            </div>
          </div>
        </Link>

        )
      
        */  }

      </div>
    </div>

  </div>
        </>
    );
};

export default HomeFinal;