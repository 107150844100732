import React, { useContext } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { ManageContext } from '../../App';
import { getRndInteger, limitString, VidTime } from '../Common/SimpleFunc';

const GridVid = ({title, description, chennel, time, videoId, image_url, chennel_id, viewIn}) => {

    const [videDetail, setVideoDetail] = useContext(ManageContext); 

    return (
        <>
{ viewIn == "home" &&
   <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, description: description, time: time})}
    to={`/watch/${chennel_id}/${videoId}`} class="vid_Info" 
    style={{order: getRndInteger(0,100)}}>
      <img src={image_url}/>
      <div class="info_wrap">
        <div class="info_1">
          <h3 class="title_1">
            <BrowserView>{limitString(title, 60)}</BrowserView>
            <MobileView>{title}</MobileView>
          </h3>
          <p class="channelInfo">{chennel}</p>
          <ul class="viewTime">
            {/* <li class="view_1">16,914 views</li> */}
            <li class="time_1">{VidTime(time)} ago</li>
          </ul>
        </div>
      </div>
    </Link>
  }

{ viewIn == "history" &&
   <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, ime: time})}
    to={`/watch/${chennel_id}/${videoId}`} class="vid_Info" 
    style={{order: getRndInteger(0,100)}}>
      <img src={image_url}/>
      <div class="info_wrap">
        <div class="info_1">
          <h3 class="title_1">
            <BrowserView>{limitString(title, 60)}</BrowserView>
            <MobileView>{title}</MobileView>
          </h3>
          <p class="channelInfo">{chennel}</p>
          <ul class="viewTime">
            {/* <li class="view_1">16,914 views</li> */}
            <li class="time_1">{time}</li>
          </ul>
        </div>
      </div>
    </Link>
  }

{ viewIn == "searchMobe" &&
   <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, description: description, time: time})}
    to={`/watch/${chennel_id}/${videoId}`} class="vid_Info" 
    style={{order: getRndInteger(0,100)}}>
      <img src={image_url}/>
      <div class="info_wrap">
        <div class="info_1">
          <h3 class="title_1">
            <BrowserView>{limitString(title, 450)}</BrowserView>
            <MobileView>{title}</MobileView>
          </h3>
          <p class="channelInfo">{chennel}</p>
          <ul class="viewTime">
            {/* <li class="view_1">16,914 views</li> */}
            <li class="time_1">{VidTime(time)} ago</li>
          </ul>
        </div>
      </div>
    </Link>
  }
  {
    // check is the search view or home views 
    viewIn == "search" && 
    <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, description: description, time: time})}
      to={`/watch/${chennel_id}/${videoId}`}  class="vid_Info_src" 
        style={{order: getRndInteger(0,100)}}>
      <img src={image_url}/>
      <div class="info_wrap_src">
        <h3 class="title_2_src">
          <BrowserView>{limitString(title, 300)}</BrowserView>
          <MobileView>{title}</MobileView>
        </h3>
        <ul class="viewTime_2">
            <li class="view_1">16,914 views - {viewIn}</li>
            <li class="time_1">{VidTime(time)} ago</li>
          </ul>
          <div class="channelBrand_2">
            <p class="channelInfo_2">{chennel}</p>
          </div>
          <p class="vid_des">{description}</p>
      </div>
    </Link>
}
  {
    viewIn == "sugestVid" && 
    <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, description: description, time: time})}
    to={`/watch/${chennel_id}/${videoId}`} class="related_video"
    style={{order: getRndInteger(0,  100)}}>

      <img class="thumb_1" src={image_url}/>
        <div class="info_wrap rltVidInfo"><div class="info_1 rldInfo">
          <h3 class="title_1">{limitString(title, 30)}</h3>
          <p class="channelInfo">{chennel}</p>
          <ul class="viewTime">
            {/* <li class="view_1">16,914 views</li> */}
            <li class="time_1">{VidTime(time)} ago</li>
          </ul>
        </div>
      </div>
    </Link>
  }  
  
  {
    viewIn == "sugestVidFire" && 
    <Link onClick={()=> setVideoDetail({chennel_id: chennel_id, title: title, description: description, time: time})}
    to={`/watch/${chennel_id}/${videoId}`} class="related_video fireReltedtVid"
    style={{order: getRndInteger(0,  100)}}>

      <img class="thumb_1" src={image_url}/>
        <div class="info_wrap rltVidInfo"><div class="info_1 rldInfo">
          <h3 class="title_1">{limitString(title, 30)}</h3>
          <p class="channelInfo">{chennel}</p>
          <ul class="viewTime">
            {/* <li class="view_1">16,914 views</li> */}
            <li class="time_1">{VidTime(time)} ago</li>
          </ul>
        </div>
      </div>
    </Link>
  }  
        </>
    );
};

export default GridVid;