import { fireDatabase } from "../../Fire";
import { key, URL_SEARCH } from "../Common/SimpleFunc";
import { chennelWithStatus } from "./Database";

export const getChennelList=(dispatch, status)=> {

    let listData = []

    chennelWithStatus(status).on('child_added', (snapshot) => {
  
        const data = snapshot.val();
        listData = {id: snapshot.key, approve: data.approve, decline: data.decline, status: data.status, timestamp: data.timestamp}; // push data in array 
        
        dispatch({
          type: "ADD_APPROVE_CHENNEL",
          approveList: listData
        })
      
        }
    )
}


export const getSelectedChenelList=(dispatch, status)=> {

  let listData = []

  fireDatabase.ref('selectedChennel/').orderByChild('status').equalTo(status).on('child_added', (snapshot) => {

      const data = snapshot.val();
      listData = {id: snapshot.key, status: data.status /*, timestamp: data.timestamp */}; // push data in array 
      
      dispatch({
        type: "ADD_SELECTED_CHENNEL",
        selectedList: listData
      })
    
      }
  )
}



export const getVideoData =(dispatch)=>{

  let getVideos = '';

  let isActiveTube  = true; 

chennelWithStatus('approve').on('child_added', (snapshot) => {

    var options_search = {
        part: 'snippet',
        channelId: snapshot.key,
        key: key, 
       // q: "waz", 
        order: "date",
        maxResults: 1
    }

    URL_SEARCH.search = new URLSearchParams(options_search).toString();

    fetch(URL_SEARCH)
    .then(data => data.json())
    .then(result => 
      result.items.map(data =>{
         // console.log(data);
        dispatch({
            type: "ADD_DATAOF_CHENNEL",
            allChennelDataList: data
          })

          dispatch({
            type: "ADD_DATA_SWITCH", 
            dataSwitch:true
           })
      })
    )
    .catch((error) => {
      isActiveTube = false; 
      dispatch({
        type: "ADD_DATA_SWITCH", 
        dataSwitch:isActiveTube
       })
    });

    }


  )

}
