import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BlockIcon from '@material-ui/icons/Block';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import './style.css';
import AddChennel from './AddChennel';
import PendingChennel from './Chennel/PendingChennel';
import ApproveChennl from './Chennel/ApproveChennl';
import BlockChennel from './Chennel/BlockChennel';
import TrashChennel from './Chennel/TrashChennel';
import TestingUpdateIssue from './Chennel/TestingUpdateIssue';
import UpdateChennel from './Chennel/UpdateChennel';
import PendingAdmin from './Admin/PendingAdmin';
import ApproveAdmin from './Admin/ApproveAdmin';
import DislineAdmin from './Admin/DislineAdmin';
import AfterLoginAdminMenu from './login/AfterLoginAdminMenu';
import { UserContext } from '../../App';

const Dashboard = () => {

const [globalState, dispatch] = useContext(UserContext)

  const [activeLink, setActiveLink] = useState();
  const [dashComp, setDashComp]  = useState(); 
  const {DashComponent} = useParams(); 
  const menuActive=(vl)=>{
      setActiveLink(vl);
  }

  let iconSwitch = true; 
  const menuTab=(id)=>{
    iconSwitch = !iconSwitch; 

    const element = document.getElementById(id);
    element.parentElement.children[1].classList.toggle("showMenu");

    if(iconSwitch){

        element.children[1].style.display="block";
        element.children[2].style.display="none";

    }else {

        element.children[1].style.display="none";
        element.children[2].style.display="block";
    }
    console.log(iconSwitch)
  }

  useEffect(()=> {
      const dComp = <DashComponent/>; 
      setDashComp(DashComponent)
  }, [DashComponent])
  
    return (
    <div id="dashboard">
        {/** 
        <div id="ds_header">
             <div className="dsLogo"><img src={require('../Common/img/logo6.png').default}/></div>
             <div className="dsTitle">
                 <h2>{props.title}</h2>
            </div>
        </div>
        */}
        <div id="dsBodyWrap">

            {/**
            <div className="ds_sidebar">
                <Link to="/addChennel" onClick={()=>menuActive('addchennel')} className={`fwidth lnk_1 ${activeLink==='addchennel' ?"active":""}`}><AddIcon/><span>Add new chennel</span></Link>
                <Link to="/allChennel" onClick={()=>menuActive('chennellist')} className={`fwidth lnk_1 ${activeLink==='chennellist' ?"active":""}`}><VideoLabelIcon/><span>All chennel</span></Link>
                <Link to="/createAdmin" onClick={()=>menuActive('addadmin')} className={`fwidth lnk_1 ${activeLink==='addadmin' ?"active":""}`}><PersonAddIcon/><span>Add New Admin</span></Link>
                <Link to="/adminList" onClick={()=>menuActive('adminlist')} className={`fwidth lnk_1 ${activeLink==='adminlist' ?"active":""}`}><SupervisorAccountIcon/><span>Admin List</span></Link>
            </div>   
             */}
             <div className="sideBar">
             <div className="dsLogo"><img src={require('../Common/img/logo6.png').default}/></div>

            <ul className="ds_sidebar menuWrapper">
                <li>
                    <Link to="/dashboard/AddChennel" onClick={()=>menuActive('addchennel')} className={`fwidth lnk_1 ${activeLink==='addchennel' ?"active":""}`}><AddIcon/><span>Add new chennel</span></Link>
                </li>
                <li> 
                    <span id="chennelMenu" onClick={()=>{menuTab("chennelMenu")}} className="span_1"><VideoLabelIcon className="vidIcon"/>Chennel 
                        <ArrowDropUpIcon className="submenuIconUp"></ArrowDropUpIcon>
                        <ArrowDropDownIcon className="submenuIconDown" style={{display: "none"}}></ArrowDropDownIcon>
                    </span> 
                    <ul>
                     <li>
                         <Link to="/dashboard/ApproveChennl" onClick={()=>menuActive('chennlApprove')} className={`fwidth lnk_1 ${activeLink==='chennlApprove' ?"active":""}`}><OndemandVideoIcon/><span>Approve Chennel</span></Link>
                     </li>
                     
                     <li>
                         <Link to="/dashboard/UpdateChennel" onClick={()=>menuActive('chennlUpdate')} className={`fwidth lnk_1 ${activeLink==='chennlUpdate' ?"active":""}`}><UpdateIcon/><span>Update Chennel</span></Link>
                     </li>
                     <li>
                         <Link to="/dashboard/PendingChennel" onClick={()=>menuActive('chennelPending')} className={`fwidth lnk_1 ${activeLink==='chennelPending' ?"active":""}`}><HourglassFullIcon/><span>Pending Chennel</span></Link>
                     </li>
                     <li>
                         <Link to="/dashboard/BlockChennel" onClick={()=>menuActive('chennelBlock')} className={`fwidth lnk_1 ${activeLink==='chennelBlock' ?"active":""}`}><BlockIcon/><span>Block Chennel</span></Link>
                     </li>
                     <li>
                         <Link to="/dashboard/TrashChennel" onClick={()=>menuActive('chennelTrash')} className={`fwidth lnk_1 ${activeLink==='chennelTrash' ?"active":""}`}><DeleteIcon/><span>Trash Chennel</span></Link>
                     </li>
                    </ul>
                </li>

                {globalState.userRed.adminType === 'superadmin' && 
                <li> 
                    <span id="adminMenu" onClick={()=>{menuTab("adminMenu")}} className="span_1"><VideoLabelIcon className="vidIcon"/>Admin 
                        <ArrowDropUpIcon className="submenuIconUp"></ArrowDropUpIcon>
                        <ArrowDropDownIcon className="submenuIconDown" style={{display: "none"}}></ArrowDropDownIcon>
                    </span> 
                    <ul>
                        
                        {/**  <li><Link to="/dashboard/createAdmin" onClick={()=>menuActive('addadmin')} className={`fwidth lnk_1 ${activeLink==='addadmin' ?"active":""}`}><PersonAddIcon/><span>Add New Admin</span></Link></li>*/}
                         <li><Link to="/dashboard/adminApprove" onClick={()=>menuActive('adminapprove')} className={`fwidth lnk_1 ${activeLink==='adminapprove' ?"active":""}`}><SupervisorAccountIcon/><span>Approve Admin List</span></Link></li>
                         <li><Link to="/dashboard/adminPending" onClick={()=>menuActive('adminpending')} className={`fwidth lnk_1 ${activeLink==='adminpending' ?"active":""}`}><PersonOutlineIcon/><span>Pending Admin List</span></Link></li>
                         <li><Link to="/dashboard/adminDisline" onClick={()=>menuActive('admindisline')} className={`fwidth lnk_1 ${activeLink==='admindisline' ?"active":""}`}><NotInterestedIcon/><span>Disline Admin List</span></Link></li>
                         
                    </ul>
                </li>
                }
             {/** 
                <li><Link to="/createAdmin" onClick={()=>menuActive('addadmin')} className={`fwidth lnk_1 ${activeLink==='addadmin' ?"active":""}`}><PersonAddIcon/><span>Add New Admin</span></Link></li>
                <li><Link to="/adminList" onClick={()=>menuActive('adminlist')} className={`fwidth lnk_1 ${activeLink==='adminlist' ?"active":""}`}><SupervisorAccountIcon/><span>Admin List</span></Link></li>
            */}
            </ul>
            </div>

            <div className="bodyContent">
                
        <div className="dashLogin">
            <AfterLoginAdminMenu/>
        </div>
                {   DashComponent =="AddChennel" && <AddChennel userName={globalState.userRed.name}/>  }
                {   DashComponent =="PendingChennel" && <PendingChennel/>}
                {   DashComponent =="ApproveChennl" && <ApproveChennl/>}
                {   DashComponent =="BlockChennel" && <BlockChennel/>}
                {   DashComponent =="TrashChennel" && <TrashChennel/>}
                {   DashComponent =="UpdateChennel" && <UpdateChennel/>}
                {   DashComponent =="adminApprove" && <ApproveAdmin/>}
                {   DashComponent =="adminPending" && <PendingAdmin/>}
                {   DashComponent =="adminDisline" && <DislineAdmin/>}
            </div>
        </div>
    </div>
    );
};

export default Dashboard;