import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { fireDatabase } from '../../../Fire';
import { dateTimeShow } from '../../Common/SimpleFunc';
import { chennelUpdate, adminWithStatus, removeChennel, adminUpdate } from '../../Database/Database';
import {TheVideoReducer, videoState} from '../../../Reducer/VideoReducer'; 

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DislineAdmin = () => {
  const classes = useStyles();
  const [adminDetail, setAdminDetail] = useState([]);
  const [state, dispatch] = useReducer(TheVideoReducer, videoState) 
  const [test, setTest] = useState([]);

  const getAllAdmin=()=>{
    let listData = [];

    adminWithStatus('disline').on('child_added', (snapshot) => {
  
        const data = snapshot.val();
        console.log(data);
        //listData.push({id: snapshot.key, approve: data.approve, decline: data.decline, status: data.status, timestamp: data.timestamp}); // push data in array 
        listData = {id: snapshot.key, name: data.name, email: data.email, photo: data.photo, status: data.status, timestamp: data.timestamp}; // push data in array 
        setAdminDetail([listData])


        }
    )
  }

  const updateAdminStatus = (id, name, email, timestamp, status) => {

    adminUpdate(id, name, email, timestamp, status)
    
  }
  const deleteChennel= (id) => {
    removeChennel(id);// database remove the chennel from list
  }
  const hideRow = (id) => {
    document.getElementById(id).style.display='none';
  }
  useEffect(()=>{
        getAllAdmin(); 
  },[])

  return (
    <>
    
    
    <div className="dsTitle">
        <h2></h2>
    </div>
    <h3>Pending Chennel List</h3>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="right">Avatar</TableCell>
          <TableCell align="right">Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="pendingList">

          {adminDetail.map((row) => (
            <TableRow id={row.id} key={row.id}>
              <TableCell align="right"><img className="avatar_3" src={row.photo}></img></TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{dateTimeShow(row.timestamp)} ago</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">
                <button onClick={()=> {updateAdminStatus(row.id, row.name,row.email, row.timestamp, 'approve'); hideRow(row.id)}} className="allow"><CheckIcon></CheckIcon></button>
              </TableCell>
            </TableRow>
          ))
          }

        </TableBody>
      </Table>
    </TableContainer>
    
    </>
  );
}

export default DislineAdmin;