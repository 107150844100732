import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { ManageContext } from '../../App';
import { getRndInteger, key, limitString, URL_SEARCH, VidTime } from '../Common/SimpleFunc';
import { chennelWithStatus } from '../Database/Database';
import GridVid from '../Home/GridVid';
import './style.css'; 

const SugestTubeVid = ({video_title}) => {

  const [vid, setVid] = useState([]); 
  const [videDetail, setVideoDetail] = useContext(ManageContext); 

    const getVedioList =(chennel)=>{
      let getVideos = '';
      setVid([]); // the setVid empty when load new video
      let storeId = []; // store id then avoid duplicate id and also use for limit the video showing

      chennelWithStatus('approve').on('child_added', (snapshot) => {

          var options_search = {
            part: 'snippet',
            channelId: snapshot.key,
            key: key,
            q: video_title,   // show same releated video
            //order: "date",
            maxResults: 2
        }
    
        URL_SEARCH.search = new URLSearchParams(options_search).toString();
  
        fetch(URL_SEARCH)
        .then(data => data.json())
        .then(result => {
          result.items.map(data =>{
            if (storeId.length < 6){
                storeId.push(data.id.videoId);
                setVid(oldData=> [...oldData, data]); 
            }
          } 
        )
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  
        }
      )
        
    }

    useEffect(()=>{
      getVedioList();
    },[video_title])

    return (
        <>
          <div id="releatedVid_result" class="releatedVid_wrap">
          {
       vid.map(data=> 
            <GridVid title={data.snippet.title} 
            description={data.snippet.description} 
            chennel={data.snippet.channelTitle}
            time={data.snippet.publishTime}
            videoId={data.id.videoId}
            image_url={data.snippet.thumbnails.medium.url}
            viewIn="sugestVid">
            </GridVid> 
            )
      }
            { /*
              vid.map(data=>
              <Link onClick={()=> setVideoDetail({title: data.snippet.title, description: data.snippet.description, time: data.snippet.publishTime})} 
              to={`/watch/${data.id.videoId}`} class="related_video"
              style={{order: getRndInteger(0,  100)}}>

                <img class="thumb_1" src={data.snippet.thumbnails.medium.url}/>
                  <div class="info_wrap rltVidInfo"><div class="info_1 rldInfo">
                    <h3 class="title_1">{limitString(data.snippet.title, 30)}</h3>
                    <p class="channelInfo">{data.snippet.channelTitle}</p>
                    <ul class="viewTime">
                      <li class="time_1">{VidTime(data.snippet.publishTime)} ago</li>
                    </ul>
                  </div>
                </div>
              </Link>
              )
          */  }
          </div>
        </>
    );
};

export default SugestTubeVid;