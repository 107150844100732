export const UserState = {
    userRed: []
}

export const TheUserReducer =(state, action) =>{

        console.log(state);
    switch(action.type){
        case "ADD_USER_INFO": 
          return {userRed: action.userInfo};
        case "TEST_USER_INFO": 
        return state;
        default:
            return state; 
    }
    
}