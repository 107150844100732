import React, { useContext, useEffect, useReducer } from 'react';
import { UserContext } from '../../App';
import { fireDatabase } from '../../Fire';
import { TheUserReducer, UserState } from '../../Reducer/UserReducer';
import { historySet } from '../Database/Database';

const ViewHistory = ({idChennel, videoId, title}) => {

    const [globalState, dispatch] = useContext(UserContext)

    const addViewHistory = ()=> {
        
        const userUId = globalState.userRed.id; 
        const emailVarify =  globalState.userRed.emailVerified; 
        const userInFire = globalState.userRed.checkUserInFire;

        const  currentD = new Date(); // current Date
        const currentT = currentD.getTime(); // current miliseconds
        
        console.log("uid: " + userUId+ " eami: " + emailVarify + " userinfre :" + userInFire + " video id : "+ videoId);
        if(emailVarify && userInFire){
            historySet (userUId, idChennel, videoId, title, currentT) // call from database.js
        }
    }

    useEffect(()=>{
        addViewHistory()
    },[videoId])

    return (
        <>
        </>
    );
};

export default ViewHistory;