import React from 'react';
import Header from "../Common/Header.js";
import Sidebar from "../Common/Sidebar.js";
import './style.css'; 
import Login from '../Dashboard/login/Login.js';

const Loginpage = () => {

    return (
        <>
{/* header */}
<Header/>
{/*   content area */}

<div id="content_wrap">
  {/*  sidebar*/}
  <Sidebar menuSwitch='history'/>

  {/* - video content area*/}
  <div class="vid_content_wrap">
    <div class="topBar"></div>
    <div id="vid_result" class="vid_content">

  {
      <div className="historyLogin">
        <p className="histInfo">You need to login to go the page</p>
        <Login></Login>
      </div>

  }
  
      </div>
    </div>

  </div>
        </>
    );
};

export default Loginpage;