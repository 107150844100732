

export const key = "AIzaSyBTZTte9W6t57Ih_0b4Vh7sDvXwrLKbj_I";
//export const key = "AIzaSyAwHonbVFKdfFLoByCAH58OAeRhfVK2Jl4";
export const URL = new window.URL('https://www.googleapis.com/youtube/v3/channels');
export const URL_SEARCH = new window.URL('https://www.googleapis.com/youtube/v3/search'); // for search videos
export const URL_VIDEOS = new window.URL('https://www.googleapis.com/youtube/v3/videos'); // for sincle video 

// export const channelLIst= ["UCqdeoueUhlF0vfi89J1PAUQ","UCHqIFwM8C8MpHn62o5cyVgg","UC6z-LtmrNxcFYb3sohNgNfQ", "UC_g5hefqj5xyucjjUUEQctw", "UCu_nY4u41kH9KWFcbhJ7mPg"]; 
export const channelLIst= ["UCqdeoueUhlF0vfi89J1PAUQ","UCHqIFwM8C8MpHn62o5cyVgg"]; 

export const getRndInteger=(min, max)=> {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  export const limitString=(str, limit)=>{
    if(str.length < limit){
      return str; 
    }else {
      return str.substring(0,limit) + "..."; 
    }
  }

  export const VidTime=(publishedTime)=>{
    const  currentD = new Date(); // current Date
    const currentT = currentD.getTime(); // current miliseconds 

    const d = new Date(publishedTime); // video date
    const t = d.getTime(); // video miliseconds 
    const getVTime = currentT - t; 
    
    const getSeconds = parseInt(getVTime / 1000); // 1 sec = 1000 miliseconds
    const getMinute = parseInt(getSeconds / 60);  // 1 min = 60 sec
    const getHours = parseInt(getSeconds / 3600); // 1 hour = 3600 sec
    const getDay = parseInt(getSeconds / 86400) // 1 day = 86400 sec
    const getMonth = parseInt(getDay / 30) // 1 month = 30 days
    const getYear = parseInt(getMonth / 12) // 1 year = 12 months


    const yearsOryear = getYear > 1 ? " years" : " year"; 
    const monthsOrmonth = getMonth > 1 ? " months" : " month"; 
    const daysOrday = getDay > 1 ? " days" : " day";
    const hoursOrhour = getHours > 1 ? " hours" : " hour"; 
    const minutesOrminute = getMinute > 1 ? " minutes" : " minute"; 

    
    if(getYear > 0){
      return getYear +  yearsOryear;
    }else if (getMonth > 0) {
      return  getMonth + monthsOrmonth;
    } else if (getDay > 0) {
      return getDay + daysOrday; 
    } else if (getHours > 0){
      return getHours + hoursOrhour; 
    }else if (getHours > 0){
      return getMinute + minutesOrminute; 
    }
  }
  export const dateTimeShow =(somoy)=>{
    const  currentD = new Date(); // current Date
    const currentT = currentD.getTime(); // current miliseconds 

    const d = new Date(somoy); // video date
    const t = d.getTime(); // video miliseconds 
    const getVTime = currentT - t; 
    
    const getSeconds = parseInt(getVTime / 1000); // 1 sec = 1000 miliseconds
    const getMinute = parseInt(getSeconds / 60);  // 1 min = 60 sec
    const getHours = parseInt(getSeconds / 3600); // 1 hour = 3600 sec
    const getDay = parseInt(getSeconds / 86400) // 1 day = 86400 sec
    const getMonth = parseInt(getDay / 30) // 1 month = 30 days
    const getYear = parseInt(getMonth / 12) // 1 year = 12 months

    const yearsOryear = getYear > 1 ? " years" : " year"; 
    const monthsOrmonth = getMonth > 1 ? " months" : " month"; 
    const daysOrday = getDay > 1 ? " days" : " day";
    const hoursOrhour = getHours > 1 ? " hours" : " hour"; 
    const minutesOrminute = getMinute > 1 ? " minutes" : " minute"; 
    
    if(getYear > 0){
      return getYear +  yearsOryear;
    }else if (getMonth > 0) {
      return  getMonth + monthsOrmonth;
    } else if (getDay > 0) {
      return getDay + daysOrday; 
    } else if (getHours > 0){
      return getHours + hoursOrhour; 
    }else if (getHours > 0){
      return getMinute + minutesOrminute; 
    }

  }
  export const getRandomInt=(min, max) =>{
    return Math.floor(Math.random() * (max - min) ) + min;
  }