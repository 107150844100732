import React, { useEffect, useReducer } from 'react';
import { TheVideoReducer, videoState } from '../../Reducer/VideoReducer';
import { chennelDataGetFire } from '../Database/Database';
import GridVid from './GridVid';

const AlternativeData = () => {
    const [state, dispatch] = useReducer(TheVideoReducer, videoState);

    useEffect(()=>{
        chennelDataGetFire(dispatch);
    },[]);
    return (
        <>
            
            {
            state.allFireDataRed.map(data => 
                typeof data.items !== 'undefined' &&
                <GridVid 
                title={data.items[0].snippet.title} 
                description={data.items[0].snippet.description} 
                chennel={data.items[0].snippet.channelTitle}
                time={data.items[0].snippet.publishTime}
                videoId={data.items[0].id.videoId}
                image_url={data.items[0].snippet.thumbnails.medium.url}/* * */
                chennel_id={data.items[0].snippet.channelId}
                viewIn="home">
                </GridVid>
            )
            }
        </>
    );
};

export default AlternativeData;