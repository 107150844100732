import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import fire, { fireDatabase } from '../../Fire';
import { addChenel } from '../Database/Database';

const AddChennel = ({userName}) => {
    const [value, setValue] = React.useState([]);

    const handleChange = (event) => {
      setValue(event.target.value);
    };
    
    const formHandle=(e)=>{
        e.preventDefault();
        const arrayChenel = value.split('\n');  // array the input data
        
        arrayChenel.map(data => {
            
            const checkWhiteSpace = /^\s+$/.test(data); // test is white space available 
            const removeSpace = data.replace(/\s/g, ''); // remove space from string

            if(data != "" && checkWhiteSpace != true){
                addChenel(removeSpace, userName); // come form database.js
              {/*  fireDatabase.ref('chennel/'+ removeSpace).set({
                    approve: 0, 
                    decline: 0,
                    timestamp: t,
                    status: "pending"
                    }, (error) => {
                        if (error) {
                        console.log(error)
                        } else {
                            console.log("save successfully")
                        }
                    }
                );
                */}
            }
            
      
        }) 

    }

    return (
        <>
    <form  className="AddForm" noValidate autoComplete="off">
        <p className="noteInput">Separte chennel name by enter</p>
        <TextField
            id="outlined-multiline-flexible"
            label="Add Chennel"
            multiline
            rowsMax={4}
            value={value}
            onChange={handleChange}
            variant="outlined" 
        />
        <Button onClick={formHandle} className="saveBtn" variant="contained" color="primary">Save</Button>
    </form>

        </>
    );
};

export default AddChennel;