import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
    BrowserRouter as Router,
    useHistory,
    useLocation,
  } from "react-router-dom";
import fire, { fireDatabase, provider } from '../../../Fire';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { TheUserReducer, UserState } from '../../../Reducer/UserReducer';
import { reactLocalStorage } from 'reactjs-localstorage';
import AfterLoginMenu from './AfterLoginMenu';
import { userSet } from '../../Database/Database';
import MissingInfo from './MissingInfo';
import { UserContext } from '../../../App';
const AdminLogin = () => {

  
  let history = useHistory();
  let location = useLocation();
  
  //let { from } = location.state || { from: { pathname: "/" } };
  let { from } = location.state || { from: { pathname: "/shsAdmin" } };

    const [globalState, dispatch] = useContext(UserContext)

    //const [state, dispatch] = useReducer(TheUserReducer, UserState); 
    const [popupMisingInfo, setPopupMisingInfo] =  useState(false); 
    const [userDetail, setUserDetail] = useState([]);
    const [userExtraInfo, setUserExtraInfo] = useState({
        country: '',
        gender:'',
        ageDate: '', 
        ageMonth:'', 
        ageYear:''
      });

    const googleLogin =() => {
        fire.auth().signInWithPopup(provider).then(result => {
            var token = result.credential.accessToken;
            var user = result.user;
            const {uid, displayName, email, photoURL, emailVerified} = user; 

           
            checkUser(uid, displayName, email, photoURL, emailVerified); 

             console.log(user); 
          }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log('error \n'+ error)
          });
          
    }

    const checkUser =(uid, displayName, email, photoURL, emailVerified)=> {
    
        fireDatabase.ref('admin/'+ uid).once('value', (snapshot) => {

            const checkUserInFire = snapshot.exists();
            const userVal = snapshot.val(); 
            if(checkUserInFire && userVal.status == 'approve'){

              saveLocatlStorage(uid, displayName, email, photoURL, userVal.gender, userVal.age, userVal.location, emailVerified, checkUserInFire, userVal.adminType, userVal.status);
              history.replace(from);

            }else if(checkUserInFire && userVal.status == 'pending'){
                document.getElementById('adminLoginWrap').innerHTML = '<h3>Your admin request till pending</h3>'
            }else {
              setPopupMisingInfo(true);
              saveLocatlStorage(uid, displayName, email, photoURL, "none", "none", "none", emailVerified, checkUserInFire, 'none', 'none');
            }

          }
        )
    }
    
    const saveLocatlStorage =(uid, displayName, email, photoURL, gender, age, location, emailVerified, checkUserInFire,adminType, status) => {

        const infoSet = {
            id : uid,
            name : displayName,
            email: email,
            photo: photoURL, 
            gender: gender,
            age: age, 
            location: location,
            emailVerified: emailVerified, 
            checkUserInFire : checkUserInFire, 
            adminType: adminType,
            status: status
        };

        //reactLocalStorage.setObject("user_info", infoSet);
        //setUserDetail(reactLocalStorage.getObject("user_info"));
       // setUserDetail(infoSet);
       dispatch({
        type: "ADD_USER_INFO",
        userInfo: infoSet
      })

    }
    const userPushInFire =()=>{
    
      const location = userExtraInfo.country;
      const gender = userExtraInfo.gender;
      const age = userExtraInfo.ageMonth + " " + userExtraInfo.ageDate + " " + userExtraInfo.ageYear; 
      const status = 'pending'; 
      const adminType = 'admin'; 

      const d = new Date(); 
      const currentTime = d.getTime();
      
      fireDatabase.ref('admin/'+ globalState.userRed.id).set({ 
        name : globalState.userRed.name, 
        email: globalState.userRed.email,
        photo: globalState.userRed.photo,
        location: location,
        gender: gender, 
        age: age, 
        adminType: adminType,
        status: status, 
        timestamp: currentTime
      },
        (error) => {
          if (error) {
          console.log(error)
          } else {
                setPopupMisingInfo(false);
                saveLocatlStorage(globalState.userRed.id, globalState.userRed.name, globalState.userRed.email, globalState.userRed.photo,gender, age, location, globalState.userRed.emailVerified, true, adminType, status) 
                //history.replace(from);
                alert("save successfully")
              }
          }
      )

    }
    
    const gotToDashboard = () => {
        history.push('dashboard/AddChennel')
    }
    useEffect(()=>{

        globalState.userRed.length > 0 && globalState.userRed.checkUserInFire === false && setPopupMisingInfo(true); 
        
      },[globalState.userRed])

    return (
        <>
        {console.log(" state lengh : " + Object.keys(globalState.userRed).length + " status : " +  globalState.userRed.status + " History : " + history.length)}
        {console.log(globalState.userRed)}
        {/*console.log(Object.keys(userDetail).length + "  and : "  + userDetail.checkUserInFire) */}
        <div id="adminLoginWrap">
        {
            Object.keys(globalState.userRed).length === 0 && 
            <button className="avatar_1" onClick={googleLogin}>
                <AccountCircleIcon/><span>SIGN IN</span>
            </button>
        }

        {
           Object.keys(globalState.userRed).length > 0 && globalState.userRed.status == 'approve' && 
            <button className="avatar_1" onClick={gotToDashboard}>
                <span>DASHBOARD</span>
            </button>
        }

        </div>
        {
            popupMisingInfo === true &&
            <MissingInfo userExtraInfo={userExtraInfo} setUserExtraInfo={setUserExtraInfo} userPushInFire={userPushInFire}></MissingInfo>
         }

        </>
    );
};

export default AdminLogin;