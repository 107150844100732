import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"; 
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyD-WqDgXD2Hywd4RZRNOjBqdlA9Dt_y5sY",
  authDomain: "sunnahmedia-e6e2e.firebaseapp.com",
  databaseURL: "https://sunnahmedia-e6e2e-default-rtdb.firebaseio.com",
  projectId: "sunnahmedia-e6e2e",
  storageBucket: "sunnahmedia-e6e2e.appspot.com",
  messagingSenderId: "175545799768",
  appId: "1:175545799768:web:ea18d113d206e85899a62d",
  measurementId: "G-RYSM17S189"
  };
  // Initialize Firebase
  const fire = firebase.initializeApp(firebaseConfig);
  export const fireDatabase = new firebase.database(); 
  export const analiticsFire = new firebase.analytics();
  export const provider = new firebase.auth.GoogleAuthProvider();
  export const fbProvider = new firebase.auth.FacebookAuthProvider();


export default fire;