import React from 'react';

const EmptyLoading = () => {

    const mystyle = {
	    marginRight: "1%",
	    marginBottom: "45px"
      };

    return (
        <>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
            <div style={mystyle} class="loading"><img src={require("./img/empty.JPG").default}/></div>
        </>
    );
};

export default EmptyLoading;