import React from 'react';
import { Link } from 'react-router-dom';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

  
const Sidebar = ({menuSwitch}) => {

    return (
        <>
        {/*  sidebar
        <DesktopSidebar></DesktopSidebar> 
        */}
    <BrowserView>
       <DesktopSidebar menuSwitch={menuSwitch}/> 
    </BrowserView>
    {/** 
      <BrowserView>
      <MobileSidebar></MobileSidebar>
    </BrowserView>
    */}

        </>
    );
};

export default Sidebar;