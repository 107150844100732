export const videoDataState = {
    videoDataRed:[],
    dataSwitchRed:true

}

export const TheVideoDataReducer =(state, action) =>{

    switch(action.type){
        case "ADD_VIDEO_DATA": 
          const newSelectedDataList = [...state.videoDataRed, action.allChennelDataList]
          return {videoDataRed: newSelectedDataList};
        case "ADD_DATA_SWITCH": 
          return {dataSwitchRed: action.dataSwitch}; 
        default:
            return state; 
    }
    
}