import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileSidebar = ({menuSwitch}) => {

    return (
        <>
        {/*  sidebar*/}
        <div class="MobileSidebar_wrap">
            <ul class="mobileSidebar">
                { menuSwitch ==='home' && <li className="listOfMenu active"><Link to="/"><img src={require("./img/home_r.png").default}/><span>Home</span></Link></li> }
                { menuSwitch !=='home' && <li className="listOfMenu"> <Link to="/"><img src={require("./img/home_b.png").default}/><span>Home</span></Link></li> }
                { menuSwitch === 'trending' && <li class="listOfMenu active"><Link to="/trend"><img src={require("./img/fire_r.png").default}/><span>Trending</span></Link></li> }
                { menuSwitch !== 'trending' && <li class="listOfMenu"><Link to="/trend"><img src={require("./img/fire_b.png").default}/><span>Trending</span></Link></li> }
                { menuSwitch === 'history' && <li class="listOfMenu active"><Link to="/history"><img src={require("./img/history_r.png").default}/><span>History</span></Link></li> }
                { menuSwitch !== 'history' && <li class="listOfMenu"><Link to="/history"><img src={require("./img/history.png").default}/><span>History</span></Link></li> }
            </ul>
        </div>
        </>
    );
};

export default MobileSidebar;