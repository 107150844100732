import React, { useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
import { UserContext } from '../../App';

const PrivateRoute=({ children, ...rest })=> {

    const [globalState, dispatch] = useContext(UserContext)

    return (
      <Route
        {...rest}
        render={({ location }) =>
            globalState.userRed.email ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/loginpage",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
export default PrivateRoute;