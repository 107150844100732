import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DesktopSidebar = ({menuSwitch}) => {
    
    return (
        <>
        {/*  sidebar*/}
        <div class="sidebar">
            <ul class="sideMenu">
                {/** 
            <li class="menuList active"><Link to="/"><img src={require("./img/home_r.png").default}/><span>Home</span></Link></li>
            <li class="menuList"><Link to="#"><img src={require("./img/fire_b.png").default}/><span>Trending</span></Link></li>
            <li class="menuList"><Link to="#"><img src={require("./img/history.png").default}/><span>History</span></Link></li>
            */}
                 { menuSwitch ==='home' && <li className="menuList active"><Link to="/"><img src={require("./img/home_r.png").default}/><span>Home</span></Link></li> }
                { menuSwitch !=='home' && <li className="menuList"> <Link to="/"><img src={require("./img/home_b.png").default}/><span>Home</span></Link></li> }
                { menuSwitch === 'trending' && <li class="menuList active"><Link to="/trend"><img src={require("./img/fire_r.png").default}/><span>Trending</span></Link></li> }
                { menuSwitch !== 'trending' && <li class="menuList"><Link to="/trend"><img src={require("./img/fire_b.png").default}/><span>Trending</span></Link></li> }
                { menuSwitch === 'history' && <li class="menuList active"><Link to="/history"><img src={require("./img/history_r.png").default}/><span>History</span></Link></li> }
                { menuSwitch !== 'history' && <li class="menuList"><Link to="/history"><img src={require("./img/history.png").default}/><span>History</span></Link></li> }
          
            </ul>
        </div>

        </>
    );
};

export default DesktopSidebar;