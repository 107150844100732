import React, { useContext } from 'react';
import { UserContext } from '../../App';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';

const Profile = () => {

    const [globalState, dispatch] = useContext(UserContext);

    
    return (
        <>

{/* header */}
<Header/>
{/*   content area */}

<div id="content_wrap">
  {/*  sidebar*/}
  <Sidebar/>

  {/* - video content area*/}
  <div class="vid_content_wrap">
    <div class="topBar"></div>
    <div id="vid_result" class="vid_content">
        <div className="profileWrap">
            <div className="profileTop">
                <div className="profilePicture">
                    <img src={globalState.userRed.photo}></img>
                </div>
                <div className="nameEmail">
                    <h3>{globalState.userRed.name}</h3>
                    <p>{globalState.userRed.email}</p>
                </div>
            </div>
            <div className="profileBottom">
                <div className="information_2">
                    <div className="inforType">Gender</div><div className="userInfo_2">: {globalState.userRed.gender}</div>
                </div>
                <div className="information_2">
                    <div className="inforType">Date of birth</div><div className="userInfo_2">: {globalState.userRed.age}</div>
                </div>
                <div className="information_2">
                    <div className="inforType">Country</div><div className="userInfo_2">: {globalState.userRed.location}</div>
                </div>

            </div>
        </div>
    </div>
    </div>

  </div>
        </>
    );
};

export default Profile;