import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
    BrowserRouter as Router,
    useHistory,
    useLocation,
  } from "react-router-dom";
import fire, { fireDatabase, provider } from '../../../Fire';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { TheUserReducer, UserState } from '../../../Reducer/UserReducer';
import { reactLocalStorage } from 'reactjs-localstorage';
import AfterLoginMenu from './AfterLoginMenu';
import { userSet } from '../../Database/Database';
import MissingInfo from './MissingInfo';
import { UserContext } from '../../../App';

const Login = () => {

  
  let history = useHistory();
  let location = useLocation();
  
  let { from } = location.state || { from: { pathname: "/" } };

    const [globalState, dispatch] = useContext(UserContext)
    //const [state, dispatch] = useReducer(TheUserReducer, UserState); 
    const [popupMisingInfo, setPopupMisingInfo] =  useState(false); 
    const [userDetail, setUserDetail] = useState([]);
    const [userExtraInfo, setUserExtraInfo] = useState({
        country: '',
        gender:'',
        ageDate: '', 
        ageMonth:'', 
        ageYear:''
      });

    const googleLogin =() => {
        fire.auth().signInWithPopup(provider).then(result => {
            var token = result.credential.accessToken;
            var user = result.user;
            const {uid, displayName, email, photoURL, emailVerified} = user; 

           
            checkUser(uid, displayName, email, photoURL, emailVerified); 
            //userSet (uid, email, displayName, "none", "none", "none");     
                   /* 
            dispatch({
                type: "ADD_USER_INFO",
                userInfo: infoSet
            });

            reactLocalStorage.setObject("user_info", infoSet);
           // setUserDetail(reactLocalStorage.getObject("counter"));

            setUserDetail(reactLocalStorage.getObject("user_info"));
            */

             console.log(user); 
          }).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
            console.log('error \n'+ error)
          });
          
    }


    const checkUser =(uid, displayName, email, photoURL, emailVerified)=> {
    
        fireDatabase.ref('user/'+ uid).once('value', (snapshot) => {

            const checkUserInFire = snapshot.exists();
            const userVal = snapshot.val(); 
            if(checkUserInFire){

              saveLocatlStorage(uid, displayName, email, photoURL, userVal.gender, userVal.age, userVal.location, emailVerified, checkUserInFire);
              history.replace(from);

            }else {
              setPopupMisingInfo(true);
              saveLocatlStorage(uid, displayName, email, photoURL, "none", "none", "none", emailVerified, checkUserInFire);
            }

          }
        )
    }
    
    const saveLocatlStorage =(uid, displayName, email, photoURL, gender, age, location, emailVerified, checkUserInFire) => {

        const infoSet = {
            id : uid,
            name : displayName,
            email: email,
            photo: photoURL, 
            gender: gender,
            age: age, 
            location: location,
            emailVerified: emailVerified, 
            checkUserInFire : checkUserInFire
        };

        reactLocalStorage.setObject("user_info", infoSet);
        //setUserDetail(reactLocalStorage.getObject("user_info"));

        dispatch({
            type: "ADD_USER_INFO",
            userInfo: reactLocalStorage.getObject("user_info")
          })

    }
    const userPushInFire =()=>{
    
      const location = userExtraInfo.country;
      const gender = userExtraInfo.gender;
      const age = userExtraInfo.ageMonth + " " + userExtraInfo.ageDate + " " + userExtraInfo.ageYear; 
      
      console.log(location + gender + age + globalState.userRed.id + "  | " + globalState.userRed.name  + globalState.userRed.email)
      fireDatabase.ref('user/'+ globalState.userRed.id).set({ 
        name : globalState.userRed.name, 
        email: globalState.userRed.email,
        location: location,
        gender: gender, 
        age: age  
      },
        (error) => {
          if (error) {
          console.log(error)
          } else {
                setPopupMisingInfo(false);
                saveLocatlStorage(globalState.userRed.id, globalState.userRed.name, globalState.userRed.email, globalState.userRed.photo,gender, age, location, globalState.userRed.emailVerified, true) 
                history.replace(from);
                console.log("save successfully")
              }
          }
      )

          /*
          fireDatabase.ref('user/'+ userDetail.id).set({ 
            name : userDetail.name, 
            email: userDetail.email,
            location: userExtraInfo.country,
            gender: userExtraInfo.gender, 
            age: userExtraInfo.ageMonth + " " + userExtraInfo.ageDate + " " + userExtraInfo.ageYear,  
          },
            (error) => {
              if (error) {
              console.log(error)
              } else {
                    setPopupMisingInfo(false);
                    saveLocatlStorage(userDetail.id, userDetail.name, userDetail.email, userDetail.photo, userDetail.emailVerified, true) 
                    console.log("save successfully")
                  }
              }
          )
          */
        }
        
    useEffect(()=>{

        globalState.userRed.length === 0 && 
            dispatch({
                type: "ADD_USER_INFO",
                userInfo: reactLocalStorage.getObject("user_info")
            })
   //    userDetail.length === 0 && setUserDetail(reactLocalStorage.getObject("user_info"));
        //Object.keys(userDetail).length > 0 && userDetail.checkUserInFire === false && setPopupMisingInfo(true); 
        Object.keys(globalState.userRed).length > 0 && globalState.userRed.checkUserInFire === false && setPopupMisingInfo(true); 
        
      },[globalState.userRed])

    return (
        <>
        {/*console.log(Object.keys(userDetail).length + "  and : "  + userDetail.checkUserInFire) */}
        {console.log(Object.keys(globalState.userRed).length + "  and : "  + globalState.userRed.checkUserInFire)}

        {
            Object.keys(globalState.userRed).length === 0 && 
            <button className="avatar_1" onClick={googleLogin}>
                <AccountCircleIcon/><span>SIGN IN</span>
            </button>
        }
         {
          Object.keys(globalState.userRed).length > 0 &&
          <AfterLoginMenu avatar={globalState.userRed.photo} loginInfo={dispatch}/>
        }
        {
            popupMisingInfo === true &&
            <MissingInfo userExtraInfo={userExtraInfo} setUserExtraInfo={setUserExtraInfo} userPushInFire={userPushInFire}></MissingInfo>
         }

         {
             /** 
              * 
                     {
            Object.keys(userDetail).length === 0 && 
            <button className="avatar_1" onClick={googleLogin}>
                <AccountCircleIcon/><span>SIGN IN</span>
            </button>
        }
         {
          Object.keys(userDetail).length > 0 &&
          <AfterLoginMenu avatar={userDetail.photo} loginInfo={setUserDetail}/>
        }

        {
            popupMisingInfo === true &&
            <MissingInfo userExtraInfo={userExtraInfo} setUserExtraInfo={setUserExtraInfo} userPushInFire={userPushInFire}></MissingInfo>
         }

              */
         }
        </>
    );
};

export default Login;